import messages from '@/utils/messages'
import { createStore } from 'vuex'
import auth from './auth'
import { cars } from './cars.module'
import contracts from './contracts'
import customers from './customers'
import { invoices } from './invoices.module'
import { lectures } from './lectures.module'
import { maintenance } from './maintenance.module'
import { monitoring } from './monitoring.module'
import { reszip } from './reszip.module'
import { remoute } from './remoute.module'
import { employees } from './employees.module'
import { ats } from './ats.module'
import workload from './workload'
import { workdocuments } from './workdocuments.module'
import { useDark, useToggle } from '@vueuse/core'

const isDarkTheme = useDark()
const toggleTheme = useToggle(isDarkTheme)

export default createStore({
  state: {
    message: {
      text: null,
      type: null
    },
    routes: [
      { name: 'Главная', path: '/' },
      { name: 'Мониторинг', path: '/monitoring', permission: 'observer' },
      { name: 'ПТО', path: '/maintenance', permission: 'observer' },
      { name: 'Счета', path: '/invoices', permission: 'accounter' },
      { name: 'Договора', path: '/contracts', permission: 'contractor' },
      { name: 'Учет автомобилей', path: '/cars', permission: 'motorist' },
      { name: 'Задачи сотрудников', path: '/staff-workload', permission: 'workload' },
      { name: 'ЗИП', path: '/reszip', permission: 'reszip' },
      { name: 'Лекции', path: '/lectures', permission: 'lectures' },
      { name: 'Удаленный доступ', path: '/remoute-access', permission: 'remouteaccess' },
      { name: 'Испытания', path: '/ats', permission: 'ats' },
      { name: 'Документы сотрудников', path: '/workdocuments', permission: 'workdocuments' }
    ],
    isDarkTheme
  },
  actions: {
    changeTheme() {
      toggleTheme()
    }
  },
  mutations: {
    setMessage(state, message) {
      state.message = { ...message, text: messages[message.text] ? messages[message.text] : message.text }
    }
  },
  getters: {
    isDarkTheme(state) {
      return state.isDarkTheme
    }
  },
  modules: {
    auth,
    monitoring,
    invoices,
    cars,
    maintenance,
    contracts,
    customers,
    workload,
    lectures,
    remoute,
    reszip,
    employees,
    ats,
    workdocuments
  }
})
