import { apiClient as axios } from "./axiosAdapter";

const API_URL = '/workdocuments/';

class WorkDocumentsService {
  // Organization
  createOrganization(data) {
    return axios.post(`${API_URL}organization/`, data);
  }

  getOrganizations() {
    return axios.get(`${API_URL}organization/`);
  }

  getOrganization(id) {
    return axios.get(`${API_URL}organization/${id}/`);
  }

  updateOrganization(id, data) {
    return axios.put(`${API_URL}organization/${id}/`, data);
  }

  deleteOrganization(id) {
    return axios.delete(`${API_URL}organization/${id}/`);
  }

  // Employee
  createEmployee(data) {
    return axios.post(`${API_URL}employee/`, data);
  }

  getEmployees() {
    return axios.get(`${API_URL}employee/`);
  }

  getEmployee(id) {
    return axios.get(`${API_URL}employee/${id}/`);
  }

  updateEmployee(id, data) {
    return axios.put(`${API_URL}employee/${id}/`, data);
  }

  removeEmployee(id) {
    return axios.delete(`${API_URL}employee/${id}/`);
  }

  // EmployeeDocName
  createEmployeeDocName(data) {
    return axios.post(`${API_URL}employee-doc-name/`, data);
  }

  getEmployeeDocNames() {
    return axios.get(`${API_URL}employee-doc-name/`);
  }

  getEmployeeDocName(id) {
    return axios.get(`${API_URL}employee-doc-name/${id}/`);
  }

  updateEmployeeDocName(id, data) {
    return axios.put(`${API_URL}employee-doc-name/${id}/`, data);
  }

  deleteEmployeeDocName(id) {
    return axios.delete(`${API_URL}employee-doc-name/${id}/`);
  }
  createDocInfoFile(data) {
    return axios.post(`${API_URL}doc-info-file/`, data);
  }

  getDocInfoFiles() {
    return axios.get(`${API_URL}doc-info-file/`);
  }

  getDocInfoFile(id) {
    return axios.get(`${API_URL}doc-info-file/${id}/`);
  }

  updateDocInfoFile(id, data) {
    return axios.put(`${API_URL}doc-info-file/${id}/`, data);
  }

  deleteDocInfoFiles(fileIds) {
    return axios({
      method: 'delete',
      url: `${API_URL}doc-info-file/bulk-delete/`,
      data: { fileIds: fileIds },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  createDocInfo(form) {
    return axios.post(`${API_URL}employeedocinfo/`, form);
  }

  getDocInfos() {
    return axios.get(`${API_URL}employeedocinfo/`);
  }

  getDocInfo(id) {
    return axios.get(`${API_URL}employeedocinfo/${id}/`);
  }

  updateDocInfo(id, data) {
    return axios.put(`${API_URL}employeedocinfo/${id}/`, data);
  }

  deleteDocInfo(id) {
    return axios.delete(`${API_URL}employeedocinfo/${id}/`);
  }
  // JournalDocType
  createJournalDocType(data) {
    return axios.post(`${API_URL}journal-doc-type/`, data);
  }

  getJournalDocTypes() {
    return axios.get(`${API_URL}journal-doc-type/`);
  }

  getJournalDocType(id) {
    return axios.get(`${API_URL}journal-doc-type/${id}/`);
  }

  updateJournalDocType(id, data) {
    return axios.put(`${API_URL}journal-doc-type/${id}/`, data);
  }

  deleteJournalDocType(id) {
    return axios.delete(`${API_URL}journal-doc-type/${id}/`);
  }

  // JournalDocInfoFile
  createJournalDocInfoFile(data) {
    return axios.post(`${API_URL}journal-doc-info-file/`, data);
  }

  getJournalDocInfoFiles() {
    return axios.get(`${API_URL}journal-doc-info-file/`);
  }

  getJournalDocInfoFile(id) {
    return axios.get(`${API_URL}journal-doc-info-file/${id}/`);
  }

  updateJournalDocInfoFile(id, data) {
    return axios.put(`${API_URL}journal-doc-info-file/${id}/`, data);
  }

  deleteJournalDocInfoFiles(fileIds) {
    return axios({
      method: 'delete',
      url: `${API_URL}journal-doc-info-file/bulk-delete/`,
      data: { fileIds: fileIds },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  // InstructionDocType
  createInstructionDocType(data) {
    return axios.post(`${API_URL}instruction-doc-type/`, data);
  }

  getInstructionDocTypes() {
    return axios.get(`${API_URL}instruction-doc-type/`);
  }

  getInstructionDocType(id) {
    return axios.get(`${API_URL}instruction-doc-type/${id}/`);
  }

  updateInstructionDocType(id, data) {
    return axios.put(`${API_URL}instruction-doc-type/${id}/`, data);
  }

  deleteInstructionDocType(id) {
    return axios.delete(`${API_URL}instruction-doc-type/${id}/`);
  }

  // InstructionDocInfoFile
  createInstructionDocInfoFile(data) {
    return axios.post(`${API_URL}instruction-doc-info-file/`, data);
  }

  getInstructionDocInfoFiles() {
    return axios.get(`${API_URL}instruction-doc-info-file/`);
  }

  getInstructionDocInfoFile(id) {
    return axios.get(`${API_URL}instruction-doc-info-file/${id}/`);
  }

  updateInstructionDocInfoFile(id, data) {
    return axios.put(`${API_URL}instruction-doc-info-file/${id}/`, data);
  }

  deleteInstructionDocInfoFiles(fileIds) {
    return axios({
      method: 'delete',
      url: `${API_URL}instruction-doc-info-file/bulk-delete/`,
      data: { fileIds: fileIds },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  // Journal
  createJournal(form) {
    return axios.post(`${API_URL}journal/`, form);
  }

  getJournals() {
    return axios.get(`${API_URL}journal/`);
  }

  getJournal(id) {
    return axios.get(`${API_URL}journal/${id}/`);
  }

  updateJournal(id, data) {
    return axios.put(`${API_URL}journal/${id}/`, data);
  }

  deleteJournal(id) {
    return axios.delete(`${API_URL}journal/${id}/`);
  }

  // Instruction
  createInstruction(data) {
    return axios.post(`${API_URL}instruction/`, data);
  }

  getInstructions() {
    return axios.get(`${API_URL}instruction/`);
  }

  getInstruction(id) {
    return axios.get(`${API_URL}instruction/${id}/`);
  }

  updateInstruction(id, data) {
    return axios.put(`${API_URL}instruction/${id}/`, data);
  }

  deleteInstruction(id) {
    return axios.delete(`${API_URL}instruction/${id}/`);
  }
  createJournalEmployees(employees) {
    const payload = {
      employees: employees.map(employee => ({
        journal_id:employee.journal_id,
        employee_id: employee.employee_id,
        date_start: employee.date_start,
        date_end: employee.date_end
      }))
    };
    return axios.post(`${API_URL}journal-employee/`, payload);
  }
  getJournalEmployees() {
    return axios.get(`${API_URL}journal-employee/`);
  }

  getJournalEmployee(id) {
    return axios.get(`${API_URL}journal-employee/${id}/`);
  }

  updateJournalEmployees(data) {
    return axios.put(`${API_URL}journal-employee/bulk-update/`, data);
  }

  removeJournalEmployees(data) {
    return axios.post(`${API_URL}journal-employee/bulk-delete/`, data);
  }

  // InstructionEmployee
  createInstructionEmployees(employees) {
    const payload = {
      employees: employees.map(employee => ({
        instruction_id:employee.instruction_id,
        employee_id: employee.employee_id,
        date_start: employee.date_start,
        date_end: employee.date_end
      }))
    };
    return axios.post(`${API_URL}instruction-employee/`, payload);
  }

  getInstructionEmployees() {
    return axios.get(`${API_URL}instruction-employee/`);
  }

  getInstructionEmployee(id) {
    return axios.get(`${API_URL}instruction-employee/${id}/`);
  }

  updateInstructionEmployees(data) {
    return axios.put(`${API_URL}instruction-employee/bulk-update/`, data);
  }

  removeInstructionEmployees(data) {
    return axios.post(`${API_URL}instruction-employee/bulk-delete/`, data);
  }
}

export default new WorkDocumentsService();