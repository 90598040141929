import WorkDocumentsService from "@/services/workdocuments.service";

const initialState = {
  employees: [],
  docInfos: [],
  docInfoFiles: [],
  journalDocInfoFiles: [],
  instructionDocInfoFiles: [],
  journals: [],
  instructions: [],
  organizations: [],
  journalDocTypes: [],
  instructionDocTypes: [],
  journalEmployees: [],
  instructionEmployees: [],
  updatedEmployees: [],
  employeeIds: [],
  loading: false,
  loadingInstruction: false,
  loadingJournal: false,
  loadingDocInfo: false,
};

export const workdocuments = {
  namespaced: true,
  state: initialState,
  actions: {
    // Employee
    fetchEmployees({ commit }) {
      commit("setLoading", true);
      return WorkDocumentsService.getEmployees().then(
        response => {
          commit("setEmployees", response);
          commit("setLoading", false);
          return Promise.resolve(response);
        },
        error => {
          commit("setLoading", false);
          return Promise.reject(error);
        }
      );
    },
    fetchJournalDocTypes({ commit }) {
      return WorkDocumentsService.getJournalDocTypes().then(
        response => {
          commit("setJournalDocTypes", response);
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    fetchInstructionDocTypes({ commit }) {
      return WorkDocumentsService.getInstructionDocTypes().then(
        response => {
          commit("setInstructionDocTypes", response);
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    fetchOrganizations({ commit }) {
      return WorkDocumentsService.getOrganizations().then(
        response => {
          commit("setOrganizations", response);
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    createEmployee({ commit }, formData) {
      commit("setLoading", true);
      return WorkDocumentsService.createEmployee(formData).then(
        employee => {
          console.log(employee)
          commit("addEmployee", employee);
          commit("setMessage", { text: "createEmployeeSuccess", type: "success" }, { root: true });
          commit("setLoading", false);
          return Promise.resolve(employee);
        },
        error => {
          commit("setLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editEmployee({ commit }, { id, formData }) {
      commit("setLoading", true);
      return WorkDocumentsService.updateEmployee(id, formData).then(
        employee => {
          commit("updateEmployee", employee);
          commit("setMessage", { text: "editEmployeeSuccess", type: "success" }, { root: true });
          commit("setLoading", false);
          return Promise.resolve(employee);
        },
        error => {
          commit("setLoading", false);
          return Promise.reject(error);
        }
      );
    },
    removeEmployee({ commit }, id) {
      commit("setLoading", true);
      return WorkDocumentsService.removeEmployee(id).then(
        response => {
          commit("removeEmployee", id);
          commit("setMessage", { text: "removeEmployeeSuccess", type: "success" }, { root: true });
          commit("setLoading", false);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoading", false);
          return Promise.reject(error);
        }
      );
    },
    // DocInfo
    fetchDocInfos({ commit }) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.getDocInfos().then(
        response => {
          commit("setDocInfos", response);
          commit("setLoadingDocInfo", false);
          return Promise.resolve(response);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },
    createDocInfo({ commit }, form) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.createDocInfo(form).then(
        docinfo => {
          commit("addDocInfo", docinfo.data);
          commit("setMessage", { text: "createDocInfoSuccess", type: "success" }, { root: true });
          commit("setLoadingDocInfo", false);
          return Promise.resolve(docinfo.data);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },
    editDocInfo({ commit }, { id, form }) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.updateDocInfo(id, form).then(
        docinfo => {
          commit("setLoadingDocInfo", false);
          commit("setMessage", { text: "editDocInfoSuccess", type: "success" }, { root: true });
          commit("updateDocInfo", docinfo);
          return Promise.resolve(docinfo);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },
    removeDocInfo({ commit }, id) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.deleteDocInfo(id).then(
        response => {
          commit("removeDocInfo", id);
          commit("setMessage", { text: "removeDocInfoSuccess", type: "success" }, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },

    // DocInfoFile
    fetchDocInfoFiles({ commit }) {
      return WorkDocumentsService.getDocInfoFiles().then(
        response => {
          commit("setDocInfoFiles", response.data);
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    createDocInfoFile({ commit }, files) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.createDocInfoFile(files).then(
        DocInfoFile => {
          commit("setLoadingDocInfo", false);
          commit("setMessage", { text: "createDocInfoFileSuccess", type: "success" }, { root: true });
          commit("addDocInfoFile", DocInfoFile.data);
          return Promise.resolve(DocInfoFile.data);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },
    editDocInfoFile({ commit }, { id, formData }) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.editDocInfoFile(id, formData).then(
        DocInfoFile => {
          commit("setLoadingDocInfo", false);
          commit("setMessage", { text: "editDocInfoFileSuccess", type: "success" }, { root: true });
          commit("updateDocInfoFile", DocInfoFile.data);
          return Promise.resolve(DocInfoFile.data);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },
    removeDocInfoFiles({ commit }, fileIds) {
      commit("setLoadingDocInfo", true);
      return WorkDocumentsService.deleteDocInfoFiles(fileIds).then(
        response => {
          commit("setLoadingDocInfo", false);
          commit("setMessage", { text: "removeDocInfoFilesSuccess", type: "success" }, { root: true });
          commit("removeDocInfoFile", fileIds);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingDocInfo", false);
          return Promise.reject(error);
        }
      );
    },
    // JournalDocInfoFile
    fetchJournalDocInfoFiles({ commit }) {
      return WorkDocumentsService.getJournalDocInfoFiles().then(
        response => {
          commit("setJournalDocInfoFiles", response.data);
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    createJournalDocInfoFile({ commit }, files) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.createJournalDocInfoFile(files).then(
        journalDocInfoFile => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "createJournalDocInfoFileSuccess", type: "success" }, { root: true });
          commit("addJournalDocInfoFile", journalDocInfoFile.data);
          return Promise.resolve(journalDocInfoFile.data);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    editJournalDocInfoFile({ commit }, { id, formData }) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.editJournalDocInfoFile(id, formData).then(
        journalDocInfoFile => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "editJournalDocInfoFileSuccess", type: "success" }, { root: true });
          commit("updateJournalDocInfoFile", journalDocInfoFile.data);
          return Promise.resolve(journalDocInfoFile.data);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    removeJournalDocInfoFiles({ commit }, fileIds) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.deleteJournalDocInfoFiles(fileIds).then(
        response => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "removeJournalDocInfoFilesSuccess", type: "success" }, { root: true });
          commit("removeJournalDocInfoFile", fileIds);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    addJournalEmployees({ commit }, { employees }) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.createJournalEmployees(employees).then(
        response => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "addJournalEmployeesSuccess", type: "success" }, { root: true });
          commit('addJournalEmployees', response.data);
          return response.data;
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
  
    editJournalEmployees({ commit }, { employees }) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.updateJournalEmployees(employees).then(
        response => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "editJournalEmployeesSuccess", type: "success" }, { root: true });
          commit("updateJournalEmployees", response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    removeJournalEmployees({ commit }, { employees }) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.removeJournalEmployees(employees).then(
        response => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "removeJournalEmployeesSuccess", type: "success" }, { root: true });
          commit("removeJournalEmployee", employees);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    // Journal
    fetchJournals({ commit }) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.getJournals().then(
        response => {
          commit("setJournals", response);
          commit("setLoadingJournal", false);
          return Promise.resolve(response);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    createJournal({ commit }, form) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.createJournal(form).then(
        journal => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "createJournalSuccess", type: "success" }, { root: true });
          commit("addJournal", journal);
          return Promise.resolve(journal);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    editJournal({ commit }, { id, form }) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.updateJournal(id, form).then(
        journal => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "editJournalSuccess", type: "success" }, { root: true });
          commit("updateJournal", journal.data);
          return Promise.resolve(journal.data);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    removeJournal({ commit }, id) {
      commit("setLoadingJournal", true);
      return WorkDocumentsService.deleteJournal(id).then(
        response => {
          commit("setLoadingJournal", false);
          commit("setMessage", { text: "removeJournalSuccess", type: "success" }, { root: true });
          commit("removeJournal", id);
          return Promise.resolve(response);
        },
        error => {
          commit("setLoadingJournal", false);
          return Promise.reject(error);
        }
      );
    },
    // Instruction
    fetchInstructionDocInfoFiles({ commit }) {
      return WorkDocumentsService.getInstructionDocInfoFiles().then(
        response => {
          commit("setInstructionDocInfoFiles", response.data);
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    createInstructionDocInfoFile({ commit }, files) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.createInstructionDocInfoFile(files).then(
        instructionDocInfoFile => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "createInstructionDocInfoFileSuccess", type: "success" }, { root: true });
          commit("addInstructionDocInfoFile", instructionDocInfoFile.data);
          return Promise.resolve(instructionDocInfoFile.data);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    editInstructionDocInfoFile({ commit }, { id, formData }) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.editInstructionDocInfoFile(id, formData).then(
        instructionDocInfoFile => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "editInstructionDocInfoFileSuccess", type: "success" }, { root: true });
          commit("updateInstructionDocInfoFile", instructionDocInfoFile.data);
          return Promise.resolve(instructionDocInfoFile.data);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    removeInstructionDocInfoFiles({ commit }, fileIds) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.deleteInstructionDocInfoFiles(fileIds).then(
        response => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "removeInstructionDocInfoFilesSuccess", type: "success" }, { root: true });
          commit("removeInstructionDocInfoFile", fileIds);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    addInstructionEmployees({ commit }, { employees }) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.createInstructionEmployees(employees).then(
        response => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "addInstructionEmployeesSuccess", type: "success" }, { root: true });
          commit('addInstructionEmployees', response.data);
          return response.data;
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
  
    editInstructionEmployees({ commit }, { employees }) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.updateInstructionEmployees(employees).then(
        response => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "editInstructionEmployeesSuccess", type: "success" }, { root: true });
          commit("updateInstructionEmployees", response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    removeInstructionEmployees({ commit }, { employees }) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.removeInstructionEmployees(employees).then(
        response => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "removeInstructionEmployeesSuccess", type: "success" }, { root: true });
          commit("removeInstructionEmployee", employees);
          return Promise.resolve(response.data);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    fetchInstructions({ commit }) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.getInstructions().then(
        response => {
          commit("setInstructions", response);
          commit("setLoadingInstruction", false);
          return Promise.resolve(response);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    createInstruction({ commit }, form) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.createInstruction(form).then(
        instruction => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "createInstructionSuccess", type: "success" }, { root: true });
          commit("addInstruction", instruction);
          return Promise.resolve(instruction);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    editInstruction({ commit }, { id, form }) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.updateInstruction(id, form).then(
        instruction => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "editInstructionSuccess", type: "success" }, { root: true });
          commit("updateInstruction", instruction.data);
          return Promise.resolve(instruction.data);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
    removeInstruction({ commit }, id) {
      commit("setLoadingInstruction", true);
      return WorkDocumentsService.deleteInstruction(id).then(
        response => {
          commit("setLoadingInstruction", false);
          commit("setMessage", { text: "removeInstructionSuccess", type: "success" }, { root: true });
          commit("removeInstruction", id);
          return Promise.resolve(response);
        },
        error => {
          commit("setLoadingInstruction", false);
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoadingInstruction(state, loadingInstruction) {
      state.loadingInstruction = loadingInstruction;
    },
    setLoadingJournal(state, loadingJournal) {
      state.loadingJournal = loadingJournal;
    },
    setLoadingDocInfo(state, loadingDocInfo) {
      state.loadingDocInfo = loadingDocInfo;
    },
    setJournalDocTypes(state, journalDocTypes) {
      state.journalDocTypes = journalDocTypes;
    },
    setJournalDocType(state, journalDocType) {
      const index = state.journalDocTypes.findIndex(item => item.id === journalDocType.id);
      if (index !== -1) {
        state.journalDocTypes.splice(index, 1, journalDocType);
      } else {
        state.journalDocTypes.push(journalDocType);
      }
    },
    setInstructionDocTypes(state, instructionDocTypes) {
      state.instructionDocTypes = instructionDocTypes;
    },
    setInstructionDocType(state, instructionDocType) {
      const index = state.instructionDocTypes.findIndex(item => item.id === instructionDocType.id);
      if (index !== -1) {
        state.instructionDocTypes.splice(index, 1, instructionDocType);
      } else {
        state.instructionDocTypes.push(instructionDocType);
      }
    },
    // Employee
    setEmployees(state, employees) {
      state.employees = employees;
    },
    addEmployee(state, employee) {
      state.employees.push(employee);
    },
    updateEmployee(state, updatedEmployee) {
      const index = state.employees.findIndex(employee => employee.id === updatedEmployee.id);
      if (index !== -1) {
        state.employees.splice(index, 1, updatedEmployee);
      }
    },
    removeEmployee(state, id) {
      state.employees = state.employees.filter(employee => employee.id !== id);
    },
    // DocInfo
    setDocInfos(state, docInfos) {
      state.docInfos = docInfos;
    },
    addDocInfo(state, docInfo) {
      state.docInfos.push(docInfo);
    },
    updateDocInfo(state, updatedDocInfo) {
      const index = state.docInfos.findIndex(docInfo => docInfo.id === updatedDocInfo.id);
      if (index !== -1) {
        state.docInfos.splice(index, 1, updatedDocInfo);
      }
    },
    removeDocInfo(state, id) {
      state.docInfos = state.docInfos.filter(docInfo => docInfo.id !== id);
    },
    // DocInfoFile
    setDocInfoFiles(state, DocInfoFiles) {
      state.DocInfoFiles = DocInfoFiles;
    },
    addDocInfoFile(state, DocInfoFile) {
      state.DocInfoFiles.push(DocInfoFile);
    },
    updateDocInfoFile(state, updatedDocInfoFile) {
      const index = state.DocInfoFiles.findIndex(DocInfoFile => DocInfoFile.id === updatedDocInfoFile.id);
      if (index !== -1) {
        state.DocInfoFiles.splice(index, 1, updatedDocInfoFile);
      }
    },
    removeDocInfoFile(state, id) {
      state.DocInfoFiles = state.DocInfoFiles.filter(DocInfoFile => DocInfoFile.id !== id);
    },
    // JournalDocInfo
    addJournalEmployees(state, employees) {
      state.journalEmployees.push(...employees);
    },
    updateJournalEmployees(state, updatedEmployees) {
      const journal = state.journals.find(journal => journal.id === updatedEmployees.journal);
      if (journal) {
        journal.employees = updatedEmployees.employees;
      }
    },
    removeJournalEmployee(state, employeeIds) {
      state.journals.forEach(journal => {
        journal.journal_employees = journal.journal_employees.filter(emp => !employeeIds.includes(emp.id));
      });
    },
    // JournalDocInfoFile
    setJournalDocInfoFiles(state, journalDocInfoFiles) {
      state.journalDocInfoFiles = journalDocInfoFiles;
    },
    addJournalDocInfoFile(state, journalDocInfoFile) {
      state.journalDocInfoFiles.push(journalDocInfoFile);
    },
    updateJournalDocInfoFile(state, updatedJournalDocInfoFile) {
      const index = state.journalDocInfoFiles.findIndex(journalDocInfoFile => journalDocInfoFile.id === updatedJournalDocInfoFile.id);
      if (index !== -1) {
        state.journalDocInfoFiles.splice(index, 1, updatedJournalDocInfoFile);
      }
    },
    removeJournalDocInfoFile(state, id) {
      state.journalDocInfoFiles = state.journalDocInfoFiles.filter(journalDocInfoFile => journalDocInfoFile.id !== id);
    },
    // InstructionDocInfoFile
    addInstructionEmployees(state, employees) {
      state.instructionEmployees.push(...employees);
    },
    updateInstructionEmployees(state, updatedEmployees) {
      const instruction = state.instructions.find(instruction => instruction.id === updatedEmployees.instruction);
      if (instruction) {
        instruction.employees = updatedEmployees.employees;
      }
    },
    removeInstructionEmployee(state, employeeIds) {
      state.instructions.forEach(instruction => {
        instruction.instruction_employees = instruction.instruction_employees.filter(emp => !employeeIds.includes(emp.id));
      });
    },
    // InstructionDocInfoFile
    setInstructionDocInfoFiles(state, instructionDocInfoFiles) {
      state.instructionDocInfoFiles = instructionDocInfoFiles;
    },
    addInstructionDocInfoFile(state, instructionDocInfoFile) {
      state.instructionDocInfoFiles.push(instructionDocInfoFile);
    },
    updateInstructionDocInfoFile(state, updatedInstructionDocInfoFile) {
      const index = state.instructionDocInfoFiles.findIndex(instructionDocInfoFile => instructionDocInfoFile.id === updatedInstructionDocInfoFile.id);
      if (index !== -1) {
        state.instructionDocInfoFiles.splice(index, 1, updatedInstructionDocInfoFile);
      }
    },
    removeInstructionDocInfoFile(state, id) {
      state.instructionDocInfoFiles = state.instructionDocInfoFiles.filter(instructionDocInfoFile => instructionDocInfoFile.id !== id);
    },
    // Journal
    setJournals(state, journals) {
      state.journals = journals;
    },
    addJournal(state, journal) {
      state.journals.push(journal);
    },
    updateJournal(state, updatedJournal) {
      if (updatedJournal && updatedJournal.id) {
        const index = state.journals.findIndex(journal => journal.id === updatedJournal.id);
        if (index !== -1) {
          state.journals.splice(index, 1, updatedJournal);
        }
      }
    },
    removeJournal(state, id) {
      state.journals = state.journals.filter(journal => journal.id !== id);
    },
    // Instruction
    setInstructions(state, instructions) {
      state.instructions = instructions;
    },
    addInstruction(state, instruction) {
      state.instructions.push(instruction);
    },
    updateInstruction(state, updatedInstruction) {
      if (updatedInstruction && updatedInstruction.id) {
        const index = state.instructions.findIndex(instruction => instruction.id === updatedInstruction.id);
        if (index !== -1) {
          state.instructions.splice(index, 1, updatedInstruction);
        }
      }
    },
    removeInstruction(state, id) {
      state.instructions = state.instructions.filter(instruction => instruction.id !== id);
    },
     // Organization
     setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    addOrganization(state, organization) {
      state.organizations.push(organization);
    },
    updateOrganization(state, updatedOrganization) {
      const index = state.organizations.findIndex(organization => organization.id === updatedOrganization.id);
      if (index !== -1) {
        state.organizations.splice(index, 1, updatedOrganization);
      }
    },
    removeOrganization(state, id) {
      state.organizations = state.organizations.filter(organization => organization.id !== id);
    },
  },
}
